import { ClassData } from '@models/class-data';

export class EventCategory {
  id: number;
  createdAt?: Date;

  parentCategoryId?: number;

  name: string;
  title: string;
  subtitle: string;

  targetGroups?: number[];
  keywords?: number[];
  subCategories?: number[];

  constructor(json: ClassData<EventCategory>) {
    this.id = +json.id;
    if (json.createdAt) {
      this.createdAt = new Date(json.createdAt);
    }

    if (json.parentCategoryId !== undefined) {
      this.parentCategoryId = +json.parentCategoryId;
    }

    this.name = json.name;
    this.title = json.title;
    this.subtitle = json.subtitle;
  }
}
